<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        :disabled="!$sl_hasAccess($sl_features.manageUsers)"
        variant="tertiary"
        color="grey"
        size="2xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(role)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="getIcon(action.icon)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { defaultRoleSettings, roleSettingTypes } from '@/config/users/users.config';

export default {
  name: 'RoleActionsDropdown',
  mixins: [modal, access],
  props: {
    actions: {
      type: Number,
      required: true
    },
    role: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.CustomFields.EditAction'),
          icon: 'pen',
          action: ({ rowId }) => this.handleEditRole(rowId)
        },
        {
          flag: 1,
          label: this.$t('Web.CustomFields.DeleteAction'),
          icon: 'trash',
          action: ({ row }) => this.handleDeleteRole(row.id, row.Name.val)
        }
      ]
    };
  },
  computed: {
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      deleteRole: 'userAccess/deleteRole',
      fetchRoleById: 'userAccess/fetchRoleById'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    handleEditRole(id) {
      this.$router.push({
        name: routeNames.EDIT_ROLE,
        params: {
          roleId: id
        }
      });
    },
    async handleDeleteRole(id, name) {
      const { settings } = await this.fetchRoleById({ id, settings: defaultRoleSettings(this) });
      const dependentForecastVersions = settings[roleSettingTypes.DEPENDENT_THINGS].forecastVersions;
      let text = '';

      if (dependentForecastVersions.length) {
        text += `${this.$t('Web.CustomFields.RoleDelete', {
          1: dependentForecastVersions.join(', ')
        })}`;
      }

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Users.DeleteRole', { 1: name }),
        text,
        icon: 'style_warning',
        confirmText: this.$t('Web.Project.Delete'),
        confirmCallback: () => this.deleteRole(id)
      });
    }
  }
};
</script>
